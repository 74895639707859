import { FC, memo } from "react";

import { ToggleGroup } from "combinezone/core";
import { StringAndOrQueryInput } from "#Models/common";

const TOGGLE_GROUPS = {
    EQUALITY: [
        { testId: 'equal', content: 'Равно' },
        { testId: 'notEqual', content: 'Не равно' },
    ],
    OPERANDS: [
        { testId: 'or', content: 'Или', value: StringAndOrQueryInput.Or },
        { testId: 'and', content: 'И', value: StringAndOrQueryInput.And },
    ],
} as const;

type CommonToggleProps = {
    negationOperator: string;
    operand: typeof StringAndOrQueryInput[keyof typeof StringAndOrQueryInput];
    setOp: (newOperator: string) => void;
};

export const OperatorToggle: FC<CommonToggleProps> = memo(
    ({ negationOperator, operand, setOp }) => {
        return (
            <ToggleGroup
                items={TOGGLE_GROUPS.EQUALITY.map((item) => ({
                    ...item,
                    isActive:
                        item.testId === 'equal' ? !negationOperator : !!negationOperator,
                    onClick: () =>
                        setOp(item.testId === 'equal' ? operand : 'n' + operand),
                }))}
            />
        );
    },
);

export const OperandToggle: FC<CommonToggleProps> = memo(
    ({ negationOperator, operand, setOp }) => {
        return (
            <ToggleGroup
                items={TOGGLE_GROUPS.OPERANDS.map((item) => ({
                    ...item,
                    isActive: operand === item.value,
                    onClick: () => setOp(negationOperator + item.value),
                }))}
            />
        );
    },
);