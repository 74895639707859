import { FC, useCallback, useEffect } from 'react';

import { NormalizedSelectOption, Typeahead } from 'combinezone/core';

type CustomTypeaheadProps = {
    testId: string;
    value?: string[];
    onChange?: (tags: string[]) => void;
    suggestions?: NormalizedSelectOption[];
    placeholder?: string;
    getSuggestions?: any;
    canAddCustomParameter?: boolean;
};

const CustomTypeaheadClient: FC<CustomTypeaheadProps> = ({
    testId,
    value: tags = [],
    onChange,
    suggestions = [],
    placeholder,
    getSuggestions,
    canAddCustomParameter = true
}) => {
    const handleTagsChange = useCallback(
        (tags: string[]) => {
            onChange?.(tags.filter((tag) => tag.trim() !== ''));
        },
        [onChange],
    );

    useEffect(() => {
        tags.forEach((tag) => {
            const tagSelector = tag.replace(/\\/g, '\\\\');
            const button = document.querySelector<HTMLButtonElement>(
                `[data-test-id="${testId}-suggestion-tag-${tagSelector}"]`,
            );
            if (button) {
                // button.style.borderColor = '#209BCF';
                button.classList.add('custom-typeahead-button-client');
            }
        });
    }, [tags, testId]);

    return (
        <div className="custom-typeahead-client" style={{ width: '100%' }}>
            <Typeahead
                suggestions={suggestions}
                canAddCustomParameter={canAddCustomParameter}
                value={tags ?? []}
                onChange={handleTagsChange}
                testId={testId}
                placeholder={placeholder}
                getSuggestions={getSuggestions}
            />
        </div>
    );
};

export default CustomTypeaheadClient;
CustomTypeaheadClient.displayName = 'CustomTypeaheadClient';
