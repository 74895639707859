//@ts-nocheck

import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Button,
  ButtonPrimary,
  ButtonTransparent,
  Flex,
  Heading,
  Modal,
  Select,
  Spinner,
  Tag,
  Text,
  useToast,
} from 'combinezone/core';

import {
  ExportEntitiesOrganization,
  ExportEntitiesOut,
  ExportEntitiesPeriod,
  ExportEntitiesRow,
  ExportEntitiesRowTitle,
  ExportEntitiesTypes,
  ToastTitleWithLoader,
} from './ExportEntities.styles';

import {
  ExportTaskStatus,
  FromToType,
  Service,
  SubService,
  DateOrder,
  DateOrderType,
} from '#Models/common';
import SorterDropdownValues from '#Modules/Services/Components/Sorter/Sorter_DropdownValues';
import { DateTime } from 'luxon';
import { RangeDatepickerResult } from 'combinezone/core/Datepicker/types';
import { useMutation } from 'react-relay';
import { useAccount } from '#Providers/AccountProvider/context';
import { FilterName } from '#Components/Filters/models';
import {
  getFieldNameByService,
  getFiltersByService,
} from '#Modules/Services/commons';
import { capitalize, intersection, without } from 'lodash';
import { useServicesMatch } from '#Hooks/useServicesMatch';
import { observer } from 'mobx-react-lite';

import mutation from './ExportEntities.graphql';
import { ExportEntitiesMutation } from '#__artifacts/ExportEntitiesMutation.graphql';
import { useExportTaskQueue } from '#Components/PendingQueues/PendingQueues_Context';
import { useTranslation } from 'react-i18next';
import CustomRangeDatepicker from '#Components/CustomRangeDatepicker';

const SERVICES = [
  SubService.Fraud,
  SubService.Suspicious,
  SubService.Phishing,
  Service.MobileApplication,
  Service.SocialAccount,
  Service.Database,
  Service.SharingPlatform,
  Service.LimitedAccess,
  Service.MassMedia,
  Service.SocialMedia,
] as const;

const ExportEntitiesModal: FC<{
  filters: any;
  onClose: () => void;
  onSuccessCallback?: () => void;
}> = ({ filters, onClose }) => {
  const { t } = useTranslation('common');
  const { t: filterT } = useTranslation('Filters');

  const { service, from, to, field, ...rest } = filters;
  const { isAdmin, selectedOrganization, organizations } = useAccount();

  const { isPending, lastTaskStatus, startTask } = useExportTaskQueue();

  const { isResources, isLinks, isLeaks, isMedia } = useServicesMatch();

  useEffect(() => {
    if (!isPending && lastTaskStatus === ExportTaskStatus.Success) {
      onClose();
    }
  }, [isPending, lastTaskStatus]);

  const { toast, toastError } = useToast();

  const [period, setPeriod] = useState<RangeDatepickerResult>([
    DateTime.fromSeconds(from).toJSDate(),
    DateTime.fromSeconds(to).toJSDate(),
  ]);
  const [periodType, setPeriodType] = useState<
    typeof FromToType[keyof typeof FromToType]
  >(field ?? FromToType.Updated);
  const [order, setOrder] = useState<DateOrderType>(DateOrder.Desc);

  const initialValue = periodType;

  const [selectedServices, updateSelectedServices] = useState<
    typeof SERVICES[number][]
  >(
    (() => {
      if (service) {
        return [service];
      }
      if (isResources) {
        return [SubService.Fraud, SubService.Suspicious, SubService.Phishing];
      }

      if (isLinks) {
        return [Service.MobileApplication, Service.SocialAccount];
      }
      if (isLeaks) {
        return [
          Service.Database,
          Service.SharingPlatform,
          Service.LimitedAccess,
        ];
      }
      if (isMedia) {
        return [Service.MassMedia, Service.SocialMedia];
      }
      return [];
    })(),
  );

  const [organization, setOrganization] = useState(selectedOrganization);

  const services = useMemo(
    () =>
      SERVICES.map((service) => {
        if (selectedServices.includes(service)) {
          return (
            <Tag
              accent="primary"
              testId={`Type_${service}__selected`}
              key={service}
              onClose={() =>
                updateSelectedServices(
                  selectedServices.filter((selected) => selected !== service),
                )
              }
            >
              {t(`Services.${service}`)}
            </Tag>
          );
        }
        return (
          <Tag
            accent="transparent"
            testId={`Type_${service}`}
            key={service}
            onClick={() =>
              updateSelectedServices([...selectedServices, service])
            }
          >
            {t(`Services.${service}`)}
          </Tag>
        );
      }),
    [selectedServices, t],
  );

  const pseudoLink = useMemo(() => {
    if (selectedServices.length < SERVICES.length) {
      return (
        <ButtonTransparent
          accent="active"
          size="sm"
          onClick={() => updateSelectedServices([...SERVICES])}
          testId="SelectAllServices"
        >
          Выбрать все
        </ButtonTransparent>
      );
    }
    return (
      <ButtonTransparent
        accent="active"
        size="sm"
        onClick={() => updateSelectedServices([])}
        testId="UnselectAllServices"
      >
        Сбросить все
      </ButtonTransparent>
    );
  }, [selectedServices]);

  const organizationsList = useMemo(
    () => ['Все организации', ...organizations.map(({ name }) => name)],
    [organizations],
  );

  const [doExport, isInProgress] =
    useMutation<ExportEntitiesMutation>(mutation);

  const submit = useCallback(() => {
    const baseParams = [
      FilterName.Hidden,
      FilterName.States,
      FilterName.Sources,
      FilterName.ValueRegex,
      FilterName.Priority,
      FilterName.Tool,
    ].reduce(
      (previousValue, currentValue) => {
        if (rest[currentValue] !== undefined) {
          previousValue[currentValue] = rest[currentValue];
        }
        return previousValue;
      },
      {
        organization: organization ===
          "Все организации" ? null : organization,
        [periodType]: {
          from: Math.floor(DateTime.fromJSDate(period[0] as Date).toSeconds()),
          to: Math.floor(DateTime.fromJSDate(period[1] as Date).toSeconds()),
        },
        sortResults: {
          field: capitalize(periodType),
          order: order === DateOrder.Asc ? 'Asc' : 'Desc',
        },
      },
    );

    if (rest['tags_values']) {
      baseParams[FilterName.Tags] = {
        [rest['tags_operand']]: rest['tags_values'],
      };
    }

    if (rest['client_tags_values']) {
      baseParams[FilterName.ClientTags] = {
        [rest['client_tags_operand']]: rest['client_tags_values'],
      };
    }

    const selectedSubServices = intersection(
      [SubService.Suspicious, SubService.Fraud, SubService.Phishing],
      selectedServices,
    );

    const initialByServiceParams = {};

    if (selectedSubServices.length) {
      initialByServiceParams[
        getFieldNameByService(Service.FraudulentResource)
      ] = {
        [FilterName.SubService]: [
          ...selectedSubServices,
          SubService.RelatedUrl,
        ],
      };
      without(
        getFiltersByService(Service.FraudulentResource),
        FilterName.SubService,
      ).forEach((filter) => {
        if (rest[filter] !== undefined) {
          if (
            !initialByServiceParams[
            getFieldNameByService(Service.FraudulentResource)
            ]
          ) {
            initialByServiceParams[
              getFieldNameByService(Service.FraudulentResource)
            ] = {};
          }
          initialByServiceParams[
            getFieldNameByService(Service.FraudulentResource)
          ][filter] = rest[filter];
        }
      });
    }

    const byServiceParams = [
      Service.Database,
      Service.LimitedAccess,
      Service.MassMedia,
      Service.MobileApplication,
      Service.SharingPlatform,
      Service.SocialAccount,
      Service.SocialMedia,
    ].reduce((previousValue, currentValue) => {
      const fieldName = getFieldNameByService(currentValue);
      const serviceFilters = getFiltersByService(currentValue);
      if (selectedServices.includes(currentValue)) {
        previousValue[fieldName] = {};
        const field = previousValue[fieldName];
        serviceFilters.forEach((filter) => {
          if (rest[filter] !== undefined) {
            field[filter] = rest[filter];
          }
        });
      }
      return previousValue;
    }, initialByServiceParams);

    doExport({
      variables: {
        baseParams,
        byServiceParams,
      },
      onCompleted: (response, errors) => {
        toast({
          title: () => (
            <ToastTitleWithLoader>
              <Spinner size={'sm'} />
              <Heading size="md" isInverted >Экспортируем данные...</Heading>
            </ToastTitleWithLoader>
          ),
          message: 'Это может занять некоторое время',
          durationMs: 1000,
          autoClose: true,
          pauseOnHover: false,
        });
        startTask(response?.exportEntities?.id);
      },
      onError: () => {
        toastError({
          title: 'Что-то пошло не так...',
          message: 'Повторите попытку позже',
        });
      },
    });
  }, [doExport, organization, period, periodType, rest, selectedServices]);

  return (
    <Modal
      testId="ExportEntitiesModal"
      title="Экспорт"
      size="md"
      onClose={onClose}
      content={
        <ExportEntitiesOut>
          <ExportEntitiesRow>
            <ExportEntitiesRowTitle>
              <Text>Период</Text>
            </ExportEntitiesRowTitle>
            <ExportEntitiesPeriod>
              <CustomRangeDatepicker
                size="medium"
                testId="8"
                onChange={(range) => setPeriod(range as RangeDatepickerResult)}
                dateFormat="dd.MM.yyyy HH:mm"
                isTimepickerEnabled
                value={period}
                autoSubmit={false}
              />
              <SorterDropdownValues
                testId="RelatedSorter"
                initialOrder={order}
                initialField={periodType}
                onOrderChange={setOrder}
                onFieldChange={setPeriodType}
              />
            </ExportEntitiesPeriod>
          </ExportEntitiesRow>
          <ExportEntitiesRow>
            <ExportEntitiesRowTitle>
              <Text>Сервис</Text>
              {pseudoLink}
            </ExportEntitiesRowTitle>
            <ExportEntitiesTypes>{services}</ExportEntitiesTypes>
          </ExportEntitiesRow>
          {isAdmin && (
            <ExportEntitiesRow>
              <ExportEntitiesRowTitle>
                <Text>Организация</Text>
              </ExportEntitiesRowTitle>
              <ExportEntitiesOrganization>
                <Select
                  value={
                    organization === null ? 'Все организации' : organization
                  }
                  testId="SelectOrganizationForExport"
                  options={organizationsList}
                  onChange={(v) => v !== undefined && setOrganization(v)}
                />
              </ExportEntitiesOrganization>
            </ExportEntitiesRow>
          )}
        </ExportEntitiesOut>
      }
      footerContent={
        <Flex justify="flex-end" gap="8px">
          <ButtonPrimary
            type="submit"
            testId="SubmitForm"
            onClick={submit}
            isLoading={isPending || isInProgress}
            isDisabled={
              organization === undefined ||
              selectedServices.length === 0 ||
              isInProgress ||
              isPending
            }
          >
            Экспортировать
          </ButtonPrimary>
          <Button testId="cancelButton" onClick={onClose}>
            Отменить
          </Button>
        </Flex>
      }
    />
  );
};

export default observer(ExportEntitiesModal);
ExportEntitiesModal.displayName = 'ExportEntitiesModal';
