import { FC, memo } from 'react';

import { ArrayParam, StringParam, useQueryParam } from 'use-query-params';

import { Flex } from 'combinezone/core';
import { tagsQuery } from '#graphql /queries';
import CustomTypeaheadClient from '#Components/CustomTypeahead/CustomTypeaheadClient';

import FilterBase from '../Filter_Base';
import { FilterName } from '../../models';
import { useSuggestions } from './useSuggestions';
import { OperandToggle, OperatorToggle } from './Filter_AndOrQueryToggle';

type FilterAndOrQueryProps = {
    name: typeof FilterName[keyof typeof FilterName];
    label: string;
};

const FilterAndOrQueryClient: FC<FilterAndOrQueryProps> = ({ name, label }) => {
    const [value = [], setValue] = useQueryParam(
        `client_${name}_values`,
        ArrayParam,
        {
            removeDefaultsFromUrl: true,
            updateType: 'replaceIn',
        },
    );
    const [op = 'or', setOp] = useQueryParam(
        `client_${name}_operand`,
        StringParam,
        {
            removeDefaultsFromUrl: true,
            updateType: 'replaceIn',
        },
    );

    const negationOperator = op && op[0] === 'n' ? 'n' : '';
    const operand = negationOperator ? op?.substring(1) : op;

    const getTagsSuggestions = useSuggestions(tagsQuery);

    return (
        <FilterBase label={label}>
            <Flex gap="24px">
                <OperatorToggle
                    negationOperator={negationOperator}
                    operand={operand as string}
                    setOp={setOp}
                />
                <OperandToggle
                    negationOperator={negationOperator}
                    operand={operand as string}
                    setOp={setOp}
                />
            </Flex>
            <CustomTypeaheadClient
                testId={"tagsFilterAndOrQueryClient"}
                // @ts-ignore
                value={value}
                onChange={setValue}
                getSuggestions={getTagsSuggestions}
                canAddCustomParameter={false}
                // @ts-ignore
                dropdownProps={{
                    usePortal: false,
                }}
            />
        </FilterBase>
    );
};

export default memo(FilterAndOrQueryClient);
FilterAndOrQueryClient.displayName = 'FilterAndOrQueryClient';
