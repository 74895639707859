import { FC, memo } from 'react';

import { ArrayParam, StringParam, useQueryParam } from 'use-query-params';

import { tagsQuery } from '#graphql /queries';
import { Flex, Typeahead } from 'combinezone/core';

import FilterBase from '../Filter_Base';
import { FilterName } from '../../models';
import { OperatorToggle, OperandToggle } from './Filter_AndOrQueryToggle';
import { useSuggestions } from './useSuggestions';

type FilterAndOrQueryProps = {
  name: typeof FilterName[keyof typeof FilterName];
  label: string;
};

const FilterAndOrQuery: FC<FilterAndOrQueryProps> = ({ name, label }) => {
  const [value = [], setValue] = useQueryParam(`${name}_values`, ArrayParam, {
    removeDefaultsFromUrl: true,
    updateType: 'replaceIn',
  });
  const [op = 'or', setOp] = useQueryParam(`${name}_operand`, StringParam, {
    removeDefaultsFromUrl: true,
    updateType: 'replaceIn',
  });

  const negationOperator = op && op[0] === 'n' ? 'n' : '';
  const operand = negationOperator ? op?.substring(1) : op;

  const getTagsSuggestions = useSuggestions(tagsQuery);

  return (
    <FilterBase label={label}>
      <Flex gap="24px">
        <OperatorToggle
          negationOperator={negationOperator}
          operand={operand as string}
          setOp={setOp}
        />
        <OperandToggle
          negationOperator={negationOperator}
          operand={operand as string}
          setOp={setOp}
        />
      </Flex>
      <Typeahead
        testId={`Typeahead__${name}`}
        // @ts-ignore
        value={value}
        onChange={setValue}
        getSuggestions={getTagsSuggestions}
        canAddCustomParameter={false}
        // @ts-ignore
        dropdownProps={{
          usePortal: false,
        }}
      />
    </FilterBase>
  );
};

export default memo(FilterAndOrQuery);
FilterAndOrQuery.displayName = 'FilterAndOrQuery';
