import { FC, useMemo } from 'react';

import { useSearchParams } from 'react-router-dom';

import { Flex, IconButton } from 'combinezone/core';
import { Filter, FilterOff, FilterSolid } from 'combinezone/icons';

import { useAside } from '../AppAside/context';
import { AsideType } from '../AppAside/consts';
import { fromPairs } from 'lodash';

const FiltersOn = () => <FilterSolid color="#209BCF" />;

const FiltersControls: FC = () => {
  const { open, isOpen } = useAside();
  const [params, setParams] = useSearchParams();

  const hasEnabledFilters = Boolean(
    Array.from(params.keys()).filter(
      (name) =>
        !['field', 'from', 'to', 'order', 'tags_operand', 'client_tags_operand'].includes(name),
    ).length,
  );

  const resetToParams = useMemo(() => {
    const preservedParams: [string, string][] = [];
    ['field', 'from', 'to', 'order', 'tags_operand', 'client_tags_operand'].forEach((paramName) => {
      if (!!params.get(paramName)) {
        // @ts-ignore
        preservedParams.push([paramName, params.get(paramName)]);
      }
    });
    return fromPairs(preservedParams);
  }, [params]);

  return (
    <Flex gap="8px">
      <IconButton
        isPressed={isOpen(AsideType.Filters)}
        icon={hasEnabledFilters ? FiltersOn : Filter}
        onClick={(event) => {
          event.stopPropagation();
          open(AsideType.Filters);
        }}
        testId="OpenFiltersPanel"
        tooltip={null}
      />
      {hasEnabledFilters && (
        <IconButton
          icon={FilterOff}
          onClick={() => setParams(resetToParams, { replace: true })}
          testId="ResetFilters"
          tooltip={null}
        />
      )}
    </Flex>
  );
};

export default FiltersControls;
FiltersControls.displayName = 'FiltersControls';
