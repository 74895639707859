import { FC, useCallback } from 'react';

import { useRelayEnvironment } from 'react-relay';

import { fetchQuery } from 'react-relay/hooks';
import SaveFiltersButton from 'src/Features/SavedFilters';
import FiltersByEntities from '#Components/Filters/FiltersByEntities';
import { accountQuery } from '#Providers/AccountProvider/AccountPreloader';
import {
  FilterAndOrQuery,
  FilterAndOrQueryClient,
  FilterHidden,
  FilterPriority,
  FilterSources,
  FilterStates,
  FilterStatesExtraAlert,
  FilterTool,
  FilterType,
} from '#Components/Filters/FiltersByType';

import { FilterName } from './models';
import { FiltersIn, FiltersOut, FiltersSave } from './Filters.styles';

const Filters: FC = () => {
  const environment = useRelayEnvironment();

  const refetchAccount = useCallback(async (): Promise<void> => {
    try {
      await fetchQuery(environment, accountQuery, {}).toPromise();
    } catch (e) {
      console.warn(e);
    }
  }, [environment]);

  return (
    <FiltersOut>
      <FiltersIn onClick={(e) => e.stopPropagation()}>
        <FilterStatesExtraAlert />
        <FilterType />
        <FilterStates />
        <FilterAndOrQuery name={FilterName.Tags} label={'Теги'} />
        <FilterAndOrQueryClient name={FilterName.ClientTags} label={'Клиентские теги'} />
        <FilterHidden />
        <FilterSources />
        <FilterTool />
        <FilterPriority />
        <FiltersByEntities />
      </FiltersIn>
      <FiltersSave>
        <SaveFiltersButton onSuccess={refetchAccount} />
      </FiltersSave>
    </FiltersOut>
  );
};

export default Filters;
Filters.displayName = 'FiltersAsidePanel';
