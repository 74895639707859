import { useCallback, useState } from "react";

import { fetchQuery, GraphQLTaggedNode } from "relay-runtime";
import { useLazyLoadQuery, useRelayEnvironment } from "react-relay";

import { TagsQuery } from "#__artifacts/TagsQuery.graphql";
import { SelectOptionWithCustomContent } from "combinezone/core";

type QueryArgs = {
    fetchKey: number;
    nameRegex: string;
};

export const useSuggestions = (
    query: GraphQLTaggedNode
) => {
    const environment = useRelayEnvironment();
    const [queryArgs, setQueryArgs] = useState<QueryArgs>({
        fetchKey: 0,
        nameRegex: '',
    });

    const data = useLazyLoadQuery<TagsQuery>(
        query,
        { nameRegex: queryArgs.nameRegex },
        { fetchPolicy: 'store-or-network', fetchKey: queryArgs.fetchKey },
    );

    const transformTagsToSuggestions = useCallback(
        (tags: ReadonlyArray<string>): SelectOptionWithCustomContent[] => {
            return tags.map((tag) => ({
                testId: `tag_${tag}`,
                value: tag,
                content: tag,
                stringContent: tag,
            }));
        },
        [],
    );

    return useCallback(
        async (nameRegex: string): Promise<SelectOptionWithCustomContent[]> => {
            if (nameRegex === queryArgs.nameRegex) {
                return transformTagsToSuggestions(data?.tags ?? []);
            }

            try {
                await fetchQuery(environment, query, { nameRegex }).toPromise();
                setQueryArgs((prev) => ({
                    fetchKey: prev.fetchKey + 1,
                    nameRegex,
                }));
                return transformTagsToSuggestions(data?.tags ?? []);
            } catch (error) {
                console.error('Error fetching suggestions:', error);
                return [];
            }
        },
        [data, environment, query, transformTagsToSuggestions, queryArgs.nameRegex],
    );
};